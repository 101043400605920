var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"py-10 px-10"},[_c('v-card-title',[_c('v-layout',{attrs:{"justify-center":""}},[_c('h1',{staticClass:"color-text-main"},[_vm._v("AUTOCOM")])])],1),_c('v-card-text',[_c('v-layout',{attrs:{"justify-center":""}},[_c('h2',{staticClass:"color-text-third mb-5"},[_vm._v("Cambiar contraseña")])]),(!_vm.show.form)?[_c('v-layout',{attrs:{"justify-center":""}},[_c('h2',{staticClass:"mb-5"},[_vm._v(_vm._s(_vm.messageToken))])])]:[_c('v-form',{ref:"form"},[_c('v-text-field',{attrs:{"rules":[
            function (v) { return !!v || 'Este campo es requerido'; },
            function (v) { return (v && v.length >= 8) ||
              'La contraseña debe tener más de 8 caracteres'; } ],"append-icon":_vm.show.pass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show.pass ? 'text' : 'password',"label":"Contraseña nueva","placeholder":"Ingrese aquí una contraseña nueva","color":"#034f79","outlined":""},on:{"click:append":function($event){_vm.show.pass = !_vm.show.pass}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('v-text-field',{attrs:{"rules":[
            function (v) { return !!v || 'Este campo es requerido'; },
            function (v) { return (v && v.length >= 8) ||
              'La contraseña debe tener más de 8 caracteres'; },
            function (v) { return (!!v && v) === _vm.form.password ||
              'El campo de repetir contraseña debe ser igual al campo de contraseña nueva'; } ],"append-icon":_vm.show.passC ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show.passC ? 'text' : 'password',"label":"Repetir contraseña nueva","placeholder":"Repita contraseña nueva","color":"#034f79","outlined":""},on:{"click:append":function($event){_vm.show.passC = !_vm.show.passC}},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})],1)]],2),_c('v-card-actions',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-btn',{attrs:{"loading":_vm.loading.btn,"dark":"","color":"#E95233","rounded":""},on:{"click":function($event){return _vm.change()}}},[_vm._v(" Cambiar contraseña ")])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-layout',{attrs:{"justify-center":""}},[_c('v-btn',{attrs:{"to":{ name: 'login' },"small":"","dark":"","color":"#034f79","text":""}},[_c('u',[_vm._v(" Iniciar sesión ")])])],1)],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.loading.all}},[_c('v-progress-circular',{attrs:{"color":"#e95233","width":"8","indeterminate":"","size":"100"}}),_c('br'),_c('v-layout',{attrs:{"justify-center":""}},[_c('label',{staticClass:"mt-4",staticStyle:{"font-size":"20px"}},[_vm._v("Cargando...")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
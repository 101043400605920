<template>
  <v-card class="py-10 px-10">
    <v-card-title>
      <v-layout justify-center>
        <h1 class="color-text-main">AUTOCOM</h1>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-layout justify-center>
        <h2 class="color-text-third mb-5">Cambiar contraseña</h2>
      </v-layout>
      <template v-if="!show.form">
        <v-layout justify-center>
          <h2 class="mb-5">{{ messageToken }}</h2>
        </v-layout>
      </template>
      <template v-else>
        <v-form ref="form">
          <v-text-field
            v-model="form.password"
            :rules="[
              (v) => !!v || 'Este campo es requerido',
              (v) =>
                (v && v.length >= 8) ||
                'La contraseña debe tener más de 8 caracteres',
            ]"
            :append-icon="show.pass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show.pass ? 'text' : 'password'"
            label="Contraseña nueva"
            placeholder="Ingrese aquí una contraseña nueva"
            color="#034f79"
            outlined
            @click:append="show.pass = !show.pass"
          ></v-text-field>
          <v-text-field
            v-model="form.password_confirmation"
            :rules="[
              (v) => !!v || 'Este campo es requerido',
              (v) =>
                (v && v.length >= 8) ||
                'La contraseña debe tener más de 8 caracteres',
              (v) =>
                (!!v && v) === form.password ||
                'El campo de repetir contraseña debe ser igual al campo de contraseña nueva',
            ]"
            :append-icon="show.passC ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show.passC ? 'text' : 'password'"
            label="Repetir contraseña nueva"
            placeholder="Repita contraseña nueva"
            color="#034f79"
            outlined
            @click:append="show.passC = !show.passC"
          ></v-text-field>
        </v-form>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="12">
          <v-layout justify-center>
            <v-btn :loading="loading.btn" @click="change()" dark color="#E95233" rounded>
              Cambiar contraseña
            </v-btn>
          </v-layout>
        </v-col>
        <v-col cols="12">
          <v-layout justify-center>
            <v-btn :to="{ name: 'login' }" small dark color="#034f79" text>
              <u> Iniciar sesión </u>
            </v-btn>
          </v-layout>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-overlay :value="loading.all">
      <v-progress-circular
        color="#e95233"
        width="8"
        indeterminate
        size="100"
      ></v-progress-circular
      ><br />
      <v-layout justify-center>
        <label class="mt-4" style="font-size: 20px">Cargando...</label>
      </v-layout>
    </v-overlay>
  </v-card>
</template>
<script>
import alert from "@/mixins/alert";
import Api from "@/utils/api";
export default {
  mixins: [alert],
  name: "Reset",
  data() {
    return {
      email: false,
      loading: {
        all: false,
        btn: false,
      },
      show: {
        pass: false,
        passC: false,
        form: false,
      },
      messageToken: "El token para reestablecer la contraseña es inválido.",
      form: {
        password: "",
        password_confirmation: "",
        email: "",
        token: ""
      },
    };
  },
  methods: {
    validateToken() {
      this.loading.all = true;
      var token = this.$route.params.token;
      Api.Auth()
        .validateToken(token)
        .then((res) => {
          if (res.data.cod == 0) {
            this.show.form = true;
            this.messageToken = res.data.message;
            this.form.email = res.data.data.email;
            this.form.token = res.data.data.token;
            this.alert(res.data.message, "Muy bien", "success");
          } else {
            this.show.form = false;
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.show.form = false;
          this.messageToken = error.response.data.message;
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.all = false));
    },
    change() {
      if (this.$refs.form.validate()) {
      this.loading.btn = true;
      var token = this.$route.params.token;
      Api.Auth()
        .resetPassword(this.form)
        .then((res) => {
          if (res.data.cod == 0) {
            this.$router.push({ name: "login" });
            this.alert(res.data.message, "Muy bien", "success");
          } else {
            this.show.form = false;
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.btn = false));

      }
    },
  },
  mounted() {
    this.validateToken();
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/main";
</style>
